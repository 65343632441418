@import "~src/styles/generator.scss";

.list-group-item.active  {
  @extend .spot--accent-500;
  border: none;
}

.list-group-item-action:hover, .list-group-item-action:focus {
    @extend .spot--secondary-300;
}

.spot-nav:hover {
    @extend .bg-light;
    @extend .text-dark
}
