// Example: Set your color variables

$color--gray:          #000550;
$color--blue:          #233786;
$color--teal:          #C4D2FF;
$color--green:         #A2CF00;
$color--orange:        #E600FF;

// Example: Set a color shade step interval

$color--interval:     10.6%;

// Example: Map your colour names to the variables

$colors: () !default;
$colors: map-merge((
  "primary":     $color--gray,
  "secondary":   $color--blue,
  "light":       $color--teal,
  "accent":      $color--green,
  "accent-pink": $color--orange,
), $colors);

// Example: Deploy as background (bg) color utility classes

@each $key, $value in $colors {
  .spot--#{$key} {
    &-100 {
      color: lighten($value, $color--interval)!important;
      background-color: lighten($value, $color--interval * 5);
    }
    &-200 {
      color: lighten($value, $color--interval * 1)!important;
      background-color: lighten($value, $color--interval * 4);
    }
    &-300 {
      color: lighten($value, $color--interval * 5)!important;
      background-color: lighten($value, $color--interval * 2.5);
    }
    &-400 {
      color: lighten($value, $color--interval * 4)!important;
      background-color: lighten($value, $color--interval * 1);
    }
    &-500 {
      color: lighten($value, $color--interval * 4.5)!important;
      background-color: $value;
    }
    &-600 {
      color: darken($value, $color--interval * 1.5)!important;
      background-color: darken($value, $color--interval);
    }
    &-700 {
      color: darken($value, $color--interval * 0.5)!important;
      background-color: darken($value, $color--interval * 0.5);
    }
    &-800 {
      color: lighten($value, $color--interval * 1)!important;
      background-color: darken($value, $color--interval * 1);
    }
    &-900 {
      color: lighten($value, $color--interval * 2)!important;
      background-color: darken($value, $color--interval * 1.5);
    }
  }
}
