/* You can add global styles to this file, and also import other style files */

$enable-grid-classes:true;
$primary:#07689F;
$secondary:#C9D6DF;
$success:#11D3BC;
$danger:#F67280;
$info:#A2D5F2;
$warning:#FF7E67;
$light:#FAFAFA;
$dark:#4e4e4e;
$enable-gradients:false;
$spacer:1.2rem;
$border-width:1px;
$btn-border-radius:.25rem;

@import "~node_modules/bootstrap/scss/bootstrap.scss";
@import "~src/styles/generator.scss";
@import "~src/styles/lists.scss";


@font-face {
    font-family: 'LatoLatinThin';
    src: url('/assets/fonts/lato/LatoLatinThin-Regular.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/lato/LatoLatinThin-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/lato/LatoLatinThin-Regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/lato/LatoLatinThin-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: LatoLatinSemBd-Regular */
@font-face {
    font-family: 'LatoLatinSemBd';
    src: url('/assets/fonts/lato/LatoLatinSemBd-Regular.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/lato/LatoLatinSemBd-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/lato/LatoLatinSemBd-Regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/lato/LatoLatinSemBd-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: LatoLatinMed-Regular */
@font-face {
    font-family: 'LatoLatinMed';
    src: url('/assets/fonts/lato/LatoLatinMed-Regular.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/lato/LatoLatinMed-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/lato/LatoLatinMed-Regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/lato/LatoLatinMed-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

*:focus {
  outline: none;
}

html,body {
  height: 100%;
}

html
{
    position: relative;
    min-height: 100%;
}
body
{
    min-height: 100%;
    margin-bottom: 60px;
}

h1 {font-family: 'LatoLatinThin', serif;}
h2 {font-family: 'LatoLatinThin', serif;}
h3 {font-family: 'LatoLatinThin', serif;}
h4 {font-family: 'LatoLatinMed', serif}
h5 {font-family: 'LatoLatinThin', serif}
th {cursor: pointer;}
/*td {word-break:break-all;}*/

p, label, input, span {font-family: 'LatoLatinMed', serif;}

a.editable, a.editable:hover {
    text-decoration: none;
    border-bottom: dashed 1px #343a40;
}
a {
    color: #343a40;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    color: #666;
    text-decoration: underline;
}


div.sticky
{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 999;

}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    /*line-height: 60px;  Vertically center the text there */
    background-color: #f5f5f5;
    z-index: 500;
}
.footer-map {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}

.shadow {
    box-shadow: 10px 0rem 0.8rem 0px rgb(0 0 0 / 15%) !important;
}

.grecaptcha-badge {
  z-index: 9999!important;
}

.gooey
{
  // position: absolute;
  // top: 50%;
  // left: 50%;
  width: 142px;
  height: 40px;
  transform: scale(0.8);
  // margin: -20px 0 0 -71px;
  background: white;
  filter: contrast(20);
  .dot
  {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0px;
    left: 15px;
    margin-top: 16px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite;
  }
  .dots
  {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    animation: dots 2.8s infinite;
    span
    {
      display: block;
      float: left;
      width: 16px;
      height: 16px;
      margin-left: 16px;
      margin-top: 16px;
      filter: blur(4px);
      background: #000;
      border-radius: 50%;
    }
  }
}
@keyframes dot {
  50% { transform: translateX(96px);}
}
@keyframes dots {
  50% { transform: translateX(-31px); }
}
